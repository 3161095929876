/* Base styles */
:root {
  --navbar-height: 64px;
  --primary-blue: #2563eb;
  --primary-blue-dark: #1d4ed8;
  --neutral-50: #f8fafc;
  --neutral-600: #475569;
  --neutral-900: #0f172a;
}

/* Global resets and defaults */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--neutral-900);
}

/* Layout containers */
.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Blog specific styles */
.blog-container {
  min-height: 100vh;
  background-color: var(--neutral-50);
  padding-top: var(--navbar-height);
}

.blog-header {
  background: linear-gradient(to right, var(--primary-blue), var(--primary-blue-dark));
  padding: 4rem 0;
  margin-bottom: 3rem;
  color: white;
}

.blog-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.blog-header p {
  max-width: 42rem;
  margin: 0 auto;
  text-align: center;
  opacity: 0.9;
}

.blog-grid {
  display: grid;
  gap: 2rem;
  padding: 2rem 0;
}

/* Blog cards */
.blog-card {
  background: white;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.blog-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-card__image {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.blog-card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card__image img {
  transform: scale(1.05);
}

.blog-card__content {
  padding: 1.5rem;
}

.blog-card__date {
  font-size: 0.875rem;
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
}

.blog-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--neutral-900);
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.blog-card__excerpt {
  color: var(--neutral-600);
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-card__link {
  color: var(--primary-blue);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

/* Loading state */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Error state */
.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #991b1b;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 2rem auto;
  max-width: 42rem;
}


/* ---------------------------------------
 * Blog posts
 * /

/* Blog Post Styles */
.blog-post {
  padding-top: var(--navbar-height);
  background-color: var(--neutral-50);
  min-height: 100vh;
}

/* Hero Section */
.blog-post__hero {
  position: relative;
  height: 60vh;
  min-height: 400px;
  max-height: 600px;
  background-color: var(--neutral-900);
  margin-bottom: 2rem;
}

.blog-post__hero-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.blog-post__hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
}

/* Navigation */
.blog-post__nav {
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.back-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-blue);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.back-link:hover {
  color: var(--primary-blue-dark);
}

/* Article Content */
.blog-post__content {
  max-width: 768px;
  margin: 0 auto;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  padding: 2rem;
}

.blog-post__header {
  text-align: center;
  margin-bottom: 3rem;
}

.blog-post__title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--neutral-900);
  line-height: 1.2;
  margin-bottom: 1rem;
}

.blog-post__meta {
  color: var(--neutral-600);
  font-size: 1rem;
}

.blog-post__body {
  font-size: 1.125rem;
  line-height: 1.8;
  color: var(--neutral-900);
}

/* Rich text content styling */
.blog-post__body h2 {
  font-size: 1.875rem;
  font-weight: 600;
  margin: 2.5rem 0 1.5rem;
  color: var(--neutral-900);
}

.blog-post__body h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
  color: var(--neutral-900);
}

.blog-post__body p {
  margin-bottom: 1.5rem;
}

.blog-post__body img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin: 2rem 0;
}

.blog-post__body a {
  color: var(--primary-blue);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.blog-post__body a:hover {
  color: var(--primary-blue-dark);
}

.blog-post__body blockquote {
  border-left: 4px solid var(--primary-blue);
  padding-left: 1.5rem;
  margin: 2rem 0;
  font-style: italic;
  color: var(--neutral-600);
}

.blog-post__body ul,
.blog-post__body ol {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
}

.blog-post__body li {
  margin-bottom: 0.5rem;
}

/* Footer */
.blog-post__footer {
  max-width: 768px;
  margin: 3rem auto;
  text-align: center;
  padding: 2rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .blog-post__hero {
    height: 40vh;
    min-height: 300px;
  }

  .blog-post__content {
    padding: 1.5rem;
    margin: 0 1rem;
  }

  .blog-post__title {
    font-size: 2rem;
  }

  .blog-post__body {
    font-size: 1rem;
  }
}

/* Not Found State */
.not-found {
  text-align: center;
  padding: 4rem 1rem;
}

.not-found h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--neutral-900);
}


/* Responsive grid layouts */
@media screen and (min-width: 640px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Mobile optimizations */
@media screen and (max-width: 639px) {
  .blog-header {
    padding: 3rem 1rem;
  }
  
  .blog-header h1 {
    font-size: 2rem;
  }
  
  .blog-card__content {
    padding: 1.25rem;
  }
  
  .container {
    padding: 0 1rem;
  }
}

/* Utility classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}




